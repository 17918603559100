<template>
  <div
    class="prose !max-w-5xl mt-12 opacity-0 translate-y-8"
    v-intersection-observer="
      ([{ isIntersecting, target }]) => {
        if (isIntersecting) {
          gsap.to(target, {
            duration: 1.0,
            opacity: 1,
            delay: 0.2,
            y: 0,
            ease: 'expo',
          })
        }
      }
    "
  >
    <table class="">
      <thead>
        <tr>
          <th class="w-[31%] py-6 text-left">Commercial Comparison</th>
          <th class="w-[30.5%] text-center pb-0">
            <span
              class="bg-brand-blue block px-8 py-6 rounded-t-3xl shadow-2xl shadow-brand-blue"
            >
              <span class="relative top-[4px]">
                <span
                  class="block text-[24px] font-extrabold tracking-[1px] leading-none text-white"
                  >NEPTUNE</span
                >
                <span class="block font-normal text-white"
                  >Commercial Flood</span
                >
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in rows">
          <td>{{ row[0] }}</td>
          <td class="text-center py-0">
            <span
              class="bg-brand-blue text-white block px-8 py-6 shadow-2xl shadow-brand-blue"
              :class="{ 'rounded-b-3xl': i === rows.length - 1 }"
            >
              {{ row[1] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
const rows = [
  ['Building Coverage', '$4,000,000'],
  ['Apartments', '$10,000,000'],
  ['Contents Coverage', '$500,000'],
  [
    'Additional Coverage',
    'Business Interruption, Replacement Cost on Building, Rental Loss of Income, Basement Contents, Pool Repair & Refill, Building Betterments & Improvements',
  ],
  ['Elevation Certificate', 'Optional'],
  [
    'Waiting Period',
    'No wait on real estate closing or rollovers from another flood policy. Otherwise, 10 days.',
  ],
]
</script>
