<template>
  <section class="bg-brand-blue relative">
    <canvas ref="myCanvas" class="max-h-[800px]"></canvas>
    <div
      class="mx-auto max-w-7xl px-6 absolute top-0 w-full inset-x-0 top-1/2 -translate-y-[58%]"
    >
      <div class="text-center" data-test="home.title">
        <img
          src="@/assets/logo-white.svg"
          alt="Logo"
          class="w-[201px] mx-auto mb-16"
        />
        <h1
          class="text-[24px] md:text-[64px] font-wide font-extrabold tracking-[2px] max-w-[1180px] mx-auto leading-tight opacity-0 translate-y-8 uppercase text-white"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.4,
                })
              }
            }
          "
        >
          GET FLOOD INSURANCE IN TWO MINUTES OR LESS
        </h1>
        <p
          class="text-white text-[24px] opacity-[.67] max-w-[800px] mx-auto opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.45,
                })
              }
            }
          "
        >
          See how much you can save with Neptune with a free quote.
        </p>
        <form
          class="text-center mt-6 opacity-0 translate-y-8 relative z-20"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  delay: 0.5,
                  ease: 'expo',
                })
              }
            }
          "
        >
          <div class="mx-auto max-w-[572px] w-full relative">
            <div>
              <QuoteForm :baseUrl="data.baseUrl" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

  <section class="py-32">
    <div class="max-w-[1280px] mx-auto grid grid-cols-3">
      <div class="col-span-2">
        <h1
          class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[900px] leading-tight opacity-0 translate-y-8 uppercase text-black mb-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.4,
                })
              }
            }
          "
        >
          NOT ALL FLOOD INSURANCE IS CREATED EQUAL
        </h1>
        <p
          class="text-black text-[24px] opacity-[.67] max-w-[800px] opacity-0 translate-y-8"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1,
                  opacity: 1,
                  y: 0,
                  ease: 'expo',
                  delay: 0.45,
                })
              }
            }
          "
        >
          To keep it short – Back in 2012, Congress passed a law saying that if
          private flood insurance met the same minimum requirements as the
          federal program (called the National Flood Insurance Program, or
          NFIP), then lenders requiring flood insurance could also accept
          private insurance.
        </p>
      </div>
      <div>
        <canvas ref="cardCanvas"></canvas>
      </div>
    </div>
  </section>
</template>

<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import QuoteForm from '@/components/QuoteForm.vue'
import * as THREE from 'three'
import { onMounted, ref } from 'vue'
import * as dat from 'dat.gui'
import * as CANNON from 'cannon-es'
import cardLogo from '@/assets/neptuneCard.png?url'
const myCanvas = ref(null)
const cardCanvas = ref(null)
console.log(cardLogo)

const { data } = defineProps(['data'])

onMounted(() => {
  // Initialize renderer
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: cardCanvas.value,
  })
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(300, 300)
  renderer.shadowMap.enabled = true
  renderer.shadowMap.type = THREE.PCFSoftShadowMap

  // Create scene
  const scene = new THREE.Scene()

  // Create camera
  const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000)
  camera.position.z = 8
  camera.position.y = 2

  // Initialize physics world
  const physicsWorld = new CANNON.World()
  physicsWorld.gravity.set(0, -9.82, 0)
  physicsWorld.broadphase = new CANNON.NaiveBroadphase()
  physicsWorld.solver.iterations = 10

  // Add lighting
  const ambientLight = new THREE.HemisphereLight(0xffffff, 0xbfd4d2, 3)
  scene.add(ambientLight)

  const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3)
  directionalLight.position.set(1, 4, 3).multiplyScalar(3)
  directionalLight.castShadow = true
  directionalLight.shadow.mapSize.setScalar(2048)
  directionalLight.shadow.bias = -1e-4
  directionalLight.shadow.normalBias = 1e-4
  scene.add(directionalLight)

  // Create tombstone shape
  const width = 2.5
  const height = 4
  const depth = 1
  const radius = 0.5 // Radius for the top arch

  // Create a custom shape for the tombstone
  const shape = new THREE.Shape()

  // Start from bottom middle
  shape.moveTo(-width / 2 + radius, -height / 2)

  // Draw bottom right corner
  shape.lineTo(width / 2 - radius, -height / 2)
  shape.absarc(
    width / 2 - radius,
    -height / 2 + radius,
    radius,
    -Math.PI / 2,
    0,
    false,
  )

  // Draw right side
  shape.lineTo(width / 2, height / 2 - radius)

  // Draw top right corner
  shape.absarc(
    width / 2 - radius,
    height / 2 - radius,
    radius,
    0,
    Math.PI / 2,
    false,
  )

  // Draw top left corner
  shape.absarc(
    -width / 2 + radius,
    height / 2 - radius,
    radius,
    Math.PI / 2,
    Math.PI,
    false,
  )

  // Draw left side
  shape.lineTo(-width / 2, -height / 2 + radius)

  // Draw bottom left corner
  shape.absarc(
    -width / 2 + radius,
    -height / 2 + radius,
    radius,
    Math.PI,
    -Math.PI / 2,
    false,
  )
  shape.lineTo(0, height / 2)

  // Extrude settings
  const extrudeSettings = {
    steps: 1,
    depth: depth,
    bevelEnabled: true,
    bevelThickness: 0.2,
    bevelSize: 0.2,
    bevelSegments: 3,
  }

  // Create geometry
  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings)

  // Create texture loader and load texture
  const textureLoader = new THREE.TextureLoader()
  const texture = textureLoader.load(cardLogo)

  // Create materials - one for the front face, one for the rest
  const frontMaterial = new THREE.MeshStandardMaterial({
    map: texture,
    flatShading: false,
    roughness: 0.7,
    metalness: 0.1,
  })

  const sideMaterial = new THREE.MeshStandardMaterial({
    color: 0xf5f5f5,
    flatShading: false,
    roughness: 0.7,
    metalness: 0.1,
  })

  // Create an array of materials
  const materials = [
    sideMaterial, // Right side
    sideMaterial, // Left side
    sideMaterial, // Top
    sideMaterial, // Bottom
    frontMaterial, // Front
    sideMaterial, // Back
  ]

  // Create mesh with multiple materials
  const tombstone = new THREE.Mesh(geometry, materials)

  // Adjust UVs for the front face
  const positions = geometry.attributes.position
  const uvs = geometry.attributes.uv
  const faceVertices = []
  const faceUVs = []

  // Find vertices that make up the front face
  for (let i = 0; i < positions.count; i++) {
    const z = positions.getZ(i)
    if (Math.abs(z - depth) < 0.01) {
      faceVertices.push(i)
      // Calculate UVs based on x and y positions
      const x = (positions.getX(i) + width / 2) / width
      const y = (positions.getY(i) + height / 2) / height
      uvs.setXY(i, x, y)
    }
  }

  // Update the geometry
  geometry.attributes.uv.needsUpdate = true

  // Center the geometry
  tombstone.position.z = -depth / 2
  tombstone.castShadow = true
  scene.add(tombstone)

  // Add shadow-receiving plane
  const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(),
    new THREE.ShadowMaterial({
      color: 0xd81b60,
      transparent: true,
      opacity: 0.075,
      side: THREE.DoubleSide,
    }),
  )
  plane.position.y = -3
  plane.rotation.x = -Math.PI / 2
  plane.scale.setScalar(10)
  plane.receiveShadow = true
  scene.add(plane)

  // Add physics ground plane
  const groundBody = new CANNON.Body({
    mass: 0,
    shape: new CANNON.Plane(),
  })
  groundBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
  groundBody.position.y = -3
  physicsWorld.addBody(groundBody)

  // Array to store physics objects
  const physicsObjects = []

  // Function to create random geometric shapes with physics
  function createRandomShape(index) {
    const shapes = [
      {
        geo: new THREE.BoxGeometry(1, 1, 1),
        phy: new CANNON.Box(new CANNON.Vec3(0.5, 0.5, 0.5)),
      },
      { geo: new THREE.SphereGeometry(0.5), phy: new CANNON.Sphere(0.5) },
      {
        geo: new THREE.ConeGeometry(0.5, 1),
        phy: new CANNON.Cylinder(0, 0.5, 1, 8),
      },
      {
        geo: new THREE.TetrahedronGeometry(0.6),
        phy: new CANNON.Box(new CANNON.Vec3(0.3, 0.3, 0.3)),
      },
      {
        geo: new THREE.OctahedronGeometry(0.5),
        phy: new CANNON.Box(new CANNON.Vec3(0.25, 0.25, 0.25)),
      },
      { geo: new THREE.DodecahedronGeometry(0.5), phy: new CANNON.Sphere(0.5) },
      { geo: new THREE.TorusGeometry(0.4, 0.2), phy: new CANNON.Sphere(0.4) },
      { geo: new THREE.IcosahedronGeometry(0.5), phy: new CANNON.Sphere(0.5) },
      {
        geo: new THREE.CylinderGeometry(0.3, 0.3, 1),
        phy: new CANNON.Cylinder(0.3, 0.3, 1, 8),
      },
      {
        geo: new THREE.TorusKnotGeometry(0.3, 0.1),
        phy: new CANNON.Sphere(0.3),
      },
    ]

    const shapeIndex = index % shapes.length
    const color = new THREE.Color().setHSL(Math.random(), 0.7, 0.6)

    // Create Three.js mesh with standard material
    const mesh = new THREE.Mesh(
      shapes[shapeIndex].geo,
      new THREE.MeshStandardMaterial({
        color: color,
        roughness: 0.7,
        metalness: 0.1,
        flatShading: false,
      }),
    )
    mesh.castShadow = true
    mesh.receiveShadow = true
    scene.add(mesh)

    // Create Cannon.js body
    const body = new CANNON.Body({
      mass: 1,
      shape: shapes[shapeIndex].phy,
    })

    // Set random initial position
    body.position.set(
      (Math.random() - 0.5) * 4,
      5 + index * 0.5,
      (Math.random() - 0.5) * 2,
    )

    // Add random rotation
    body.angularVelocity.set(
      Math.random() * 10,
      Math.random() * 10,
      Math.random() * 10,
    )

    physicsWorld.addBody(body)

    return { mesh, body }
  }

  // Create 10 random shapes
  for (let i = 0; i < 10; i++) {
    physicsObjects.push(createRandomShape(i))
  }

  // GSAP bobbing animation for tombstone
  gsap.to(tombstone.position, {
    y: 0.2,
    duration: 2,
    yoyo: true,
    repeat: -1,
    ease: 'power1.inOut',
  })

  // Update physics and render loop
  const timeStep = 1 / 60
  function animate() {
    requestAnimationFrame(animate)

    // Step physics world
    physicsWorld.step(timeStep)

    // Update mesh positions
    physicsObjects.forEach((obj) => {
      obj.mesh.position.copy(obj.body.position)
      obj.mesh.quaternion.copy(obj.body.quaternion)
    })

    renderer.render(scene, camera)
  }

  // Start the animation loop
  animate()

  class World {
    constructor(width, height) {
      this.renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: myCanvas.value,
      })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(width, height)
      this.container = document.getElementsByClassName('world')[0]
      this.scene = new THREE.Scene()
      this.width = width
      this.height = height
      this.aspectRatio = width / height
      this.fieldOfView = 50
      var nearPlane = 0.1
      var farPlane = 20000
      this.camera = new THREE.PerspectiveCamera(
        this.fieldOfView,
        this.aspectRatio,
        nearPlane,
        farPlane,
      )
      this.camera.position.z = 200
      this.timer = 0
      this.mousePos = { x: 0, y: 0 }
      this.targetMousePos = { x: 0, y: 0 }
      this.createPlane()
      this.render()
    }

    createPlane() {
      this.material = new THREE.RawShaderMaterial({
        vertexShader: `
  // attributes of our mesh
  attribute vec3 position;
  attribute vec2 uv;

  // built-in uniforms from ThreeJS camera and Object3D
  uniform mat4 projectionMatrix;
  uniform mat4 modelViewMatrix;
  uniform mat3 normalMatrix;

  // custom uniforms to build up our tubes
  uniform float uTime;
  uniform vec2 uMousePosition;

  // pass a few things along to the vertex shader
  varying vec2 vUv;

  void main() {
    vUv = uv;
    vec4 pos = vec4(position, 1.0);
    gl_Position = pos;
  }`,
        fragmentShader: `
  precision highp float;

  uniform float uTime;
  uniform vec2 uMousePosition;
  uniform float uHue;
  uniform float uHueVariation;
  uniform float uDensity;
  uniform float uDisplacement;
  uniform float uGradient;
  
  varying vec2 vUv;

  float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
  vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
  vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

  float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
  }

  float hue2rgb(float f1, float f2, float hue) {
    if (hue < 0.0)
      hue += 1.0;
    else if (hue > 1.0)
      hue -= 1.0;
    float res;
    if ((6.0 * hue) < 1.0)
      res = f1 + (f2 - f1) * 6.0 * hue;
    else if ((2.0 * hue) < 1.0)
      res = f2;
    else if ((3.0 * hue) < 2.0)
      res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
    else
      res = f1;
    return res;
  }

  vec3 hsl2rgb(vec3 hsl) {
    vec3 rgb;
    if (hsl.y == 0.0) {
      rgb = vec3(hsl.z); // Luminance
    } else {
      float f2;
      if (hsl.z < 0.5)
        f2 = hsl.z * (1.0 + hsl.y);
      else
        f2 = hsl.z + hsl.y - hsl.y * hsl.z;
      float f1 = 2.0 * hsl.z - f2;
      rgb.r = hue2rgb(f1, f2, hsl.x + (1.0/3.0));
      rgb.g = hue2rgb(f1, f2, hsl.x);
      rgb.b = hue2rgb(f1, f2, hsl.x - (1.0/3.0));
    }
    return rgb;
  }

  vec3 hsl2rgb(float h, float s, float l) {
    return hsl2rgb(vec3(h, s, l));
  }

  float noise(vec3 p){
    vec3 a = floor(p);
    vec3 d = p - a;
    d = d * d * (3.0 - 2.0 * d);

    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
    vec4 k1 = perm(b.xyxy);
    vec4 k2 = perm(k1.xyxy + b.zzww);

    vec4 c = k2 + a.zzzz;
    vec4 k3 = perm(c);
    vec4 k4 = perm(c + 1.0);

    vec4 o1 = fract(k3 * (1.0 / 41.0));
    vec4 o2 = fract(k4 * (1.0 / 41.0));

    vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
    vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

    return o4.y * d.y + o4.x * (1.0 - d.y);
  }

  vec2 fade(vec2 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

  float cnoise(vec2 P){
    vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
    vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
    Pi = mod(Pi, 289.0); // To avoid truncation effects in permutation
    vec4 ix = Pi.xzxz;
    vec4 iy = Pi.yyww;
    vec4 fx = Pf.xzxz;
    vec4 fy = Pf.yyww;
    vec4 i = perm(perm(ix) + iy);
    vec4 gx = 2.0 * fract(i * 0.0243902439) - 1.0; // 1/41 = 0.024...
    vec4 gy = abs(gx) - 0.5;
    vec4 tx = floor(gx + 0.5);
    gx = gx - tx;
    vec2 g00 = vec2(gx.x,gy.x);
    vec2 g10 = vec2(gx.y,gy.y);
    vec2 g01 = vec2(gx.z,gy.z);
    vec2 g11 = vec2(gx.w,gy.w);
    vec4 norm = 1.79284291400159 - 0.85373472095314 *
    vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11));
    g00 *= norm.x;
    g01 *= norm.y;
    g10 *= norm.z;
    g11 *= norm.w;
    float n00 = dot(g00, vec2(fx.x, fy.x));
    float n10 = dot(g10, vec2(fx.y, fy.y));
    float n01 = dot(g01, vec2(fx.z, fy.z));
    float n11 = dot(g11, vec2(fx.w, fy.w));
    vec2 fade_xy = fade(Pf.xy);
    vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
    float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
    return 2.3 * n_xy;
  }

  void main () {
    float mouseDistance = length(vUv - uMousePosition);
    float t = uTime * .005;
    float elevation =  vUv.y * uDensity * 30.0;
    
    float shadow = smoothstep(0.0, .3 + sin(t * 5.0 * 3.14) * .1 , mouseDistance);
    elevation += shadow * 5.0;
    
    float displacement = cnoise( vec2( t + vUv.y * 2.0, t + vUv.x * 3.0 )) * uDisplacement * 3.0 ;

    elevation += displacement * 4.0;
    elevation *= 2.0 + cnoise( vec2( t + vUv.y * 1.0, t + .5)) * 2.0 ;
    
    float light = .9 + fract(elevation) ;
    light *= .9 + (1.0 - (displacement * displacement)) * .1;
    elevation = floor(elevation);
  
    // Base color: rgb(0, 76, 157) -> HSL(210/360, 1.0, 0.31)
    float baseHue = 0.583; // 210/360
    float baseSat = 1.0;
    float baseLum = 0.31;
    
    // Modify the base color with existing effects, but with reduced intensity
    float hue = baseHue + shadow * 0.02 + cnoise(vec2(elevation * 0.10, 0.1 + t)) * uHueVariation * 0.05;
    float saturation = baseSat;
    float brightness = baseLum + (- (1.0 - shadow) * 0.05 + smoothstep(0.0, 0.9, cnoise(vec2(elevation * 0.5, 0.4 + t * 5.0))) * 0.05);

    vec3 hslCol = vec3( hue, saturation, brightness);
    vec3 col = hsl2rgb(hslCol) * vec3(light, 1.0, 1.0);
    
    gl_FragColor = vec4(col, 1.);
  }`,
        uniforms: {
          uTime: { type: 'f', value: 0 },
          uHue: { type: 'f', value: 0.583 }, // 210/360
          uHueVariation: { type: 'f', value: 0.5 }, // Reduced variation
          uGradient: { type: 'f', value: 1 },
          uDensity: { type: 'f', value: 1 },
          uDisplacement: { type: 'f', value: 1 },
          uMousePosition: { type: 'v2', value: new THREE.Vector2(0.5, 0.5) },
        },
      })
      this.planeGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)

      this.plane = new THREE.Mesh(this.planeGeometry, this.material)
      this.scene.add(this.plane)
    }

    render() {
      this.timer += parameters.speed
      this.plane.material.uniforms.uTime.value = this.timer

      this.mousePos.x += (this.targetMousePos.x - this.mousePos.x) * 0.1
      this.mousePos.y += (this.targetMousePos.y - this.mousePos.y) * 0.1

      if (this.plane) {
        this.plane.material.uniforms.uMousePosition.value = new THREE.Vector2(
          this.mousePos.x,
          this.mousePos.y,
        )
      }

      this.renderer.render(this.scene, this.camera)
    }

    loop() {
      this.render()
      requestAnimationFrame(this.loop.bind(this))
    }

    updateSize(w, h) {
      this.renderer.setSize(w, h)
      this.camera.aspect = w / h
      this.camera.updateProjectionMatrix()
    }
    mouseMove(mousePos) {
      this.targetMousePos.x = mousePos.px
      this.targetMousePos.y = mousePos.py
    }
  }

  document.addEventListener('DOMContentLoaded', domIsReady)
  let mousePos = { x: 0, y: 0, px: 0, py: 0 }
  let world
  let gui = new dat.GUI()

  let parameters = {
    speed: 0.1,
    hue: 0.45,
    hueVariation: 0,
    gradient: 0.3,
    density: 1,
    displacement: 1,
  }

  function domIsReady() {
    world = new World(
      this.container,
      this.renderer,
      window.innerWidth,
      window.innerHeight,
    )
    window.addEventListener('resize', handleWindowResize, false)
    document.addEventListener('mousemove', handleMouseMove, false)
    handleWindowResize()
    world.loop()
    initGui()
  }

  var guiHue, guiSpeed, guiDensity, guiVariation, guiDisp

  function initGui() {
    gui.width = 250
    guiSpeed = gui
      .add(parameters, 'speed')
      .min(0.1)
      .max(1)
      .step(0.01)
      .name('speed')
    guiHue = gui.add(parameters, 'hue').min(0).max(1).step(0.01).name('hue')
    guiVariation = gui
      .add(parameters, 'hueVariation')
      .min(0)
      .max(1)
      .step(0.01)
      .name('hue variation')
    //guiGradient = gui.add(parameters, 'gradient').min(0).max(1).step(.01).name('inner gradient');
    guiDensity = gui
      .add(parameters, 'density')
      .min(0)
      .max(1)
      .step(0.01)
      .name('density')
    guiDisp = gui
      .add(parameters, 'displacement')
      .min(0)
      .max(1)
      .step(0.01)
      .name('displacement')

    guiHue.onChange(function (value) {
      updateParameters()
    })

    guiVariation.onChange(function (value) {
      updateParameters()
    })
    /*
  guiGradient.onChange( function(value) {
    updateParameters();
  });
  */
    guiDensity.onChange(function (value) {
      updateParameters()
    })

    guiDisp.onChange(function (value) {
      updateParameters()
    })
    updateParameters()
  }

  function updateParameters() {
    world.plane.material.uniforms.uHue.value = parameters.hue
    world.plane.material.uniforms.uHueVariation.value = parameters.hueVariation
    //world.plane.material.uniforms.uGradient.value = parameters.gradient;
    world.plane.material.uniforms.uDensity.value = parameters.density
    world.plane.material.uniforms.uDisplacement.value = parameters.displacement
  }

  function handleWindowResize() {
    world.updateSize(window.innerWidth, window.innerHeight)
  }

  function handleMouseMove(e) {
    mousePos.x = e.clientX
    mousePos.y = e.clientY
    mousePos.px = mousePos.x / window.innerWidth
    mousePos.py = 1.0 - mousePos.y / window.innerHeight
    world.mouseMove(mousePos)
  }
})
</script>
