<template>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference pointer-events-none"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div
      class="absolute inset-0 bg-brand-blue rounded-b-[50px] rigin-top"
    ></div>
    <div class="text-center relative z-10">
      <h1
        class="text-[24px] md:text-[48px] font-wide font-extrabold tracking-[2px] max-w-[420px] md:max-w-[789px] leading-tight mb-5 opacity-0 translate-y-8 uppercase text-white mx-auto"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                delay: 0.4,
                ease: 'expo',
              })
            }
          }
        "
      >
        RESIDENTIAL FLOOD INSURANCE COVERAGE
      </h1>
      <div
        class="mx-auto max-w-[532px] w-full relative opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.4,
                opacity: 1,
                y: 0,
                delay: 0.5,
                ease: 'expo',
              })
            }
          }
        "
      >
        <QuoteForm :baseUrl="data.baseUrl" buttonColor="bg-black" />
      </div>
    </div>
  </section>
  <!-- <div
    class="bg-[#D5EAE8] text-[32px] p-16 text-center text-[#555B62] rounded-3xl leading-tight max-w-[800px] mx-auto mb-32 relative overflow-hidden opacity-0 translate-y-8"
    v-intersection-observer="
      ([{ isIntersecting, target }]) => {
        if (isIntersecting) {
          gsap.to(target, {
            duration: 1.0,
            opacity: 1,
            delay: 0.25,
            y: 0,
            ease: 'expo',
          })
        }
      }
    "
  >
    <span> Approximately 25% of flood claims happen in low risk areas. </span>
    <svg
      width="101"
      height="97"
      viewBox="0 0 101 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute bottom-[0px] right-[0px]"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.695312 60.3529C0.695312 27.1454 27.6128 0.227905 60.8203 0.227905C94.0278 0.227905 120.945 27.1454 120.945 60.3529C120.945 93.5604 94.0278 120.478 60.8203 120.478C27.6128 120.478 0.695312 93.5604 0.695312 60.3529ZM54.3823 51.4606C61.4493 47.9271 69.4105 54.3157 67.4926 61.9809L63.1205 79.4696L63.3795 79.3462C64.4661 78.8685 65.6949 78.8269 66.8114 79.23C67.9279 79.6331 68.8466 80.4501 69.3775 81.5118C69.9084 82.5735 70.0107 83.7987 69.6633 84.9338C69.3159 86.0688 68.5453 87.0269 67.5111 87.6096L67.2645 87.7452C60.1913 91.2787 52.2301 84.8901 54.148 77.2249L58.5263 59.7362L58.2673 59.8596C57.7218 60.1627 57.1206 60.3524 56.4998 60.417C55.8791 60.4817 55.2517 60.4201 54.6554 60.2358C54.0591 60.0516 53.5062 59.7486 53.0301 59.3451C52.554 58.9415 52.1645 58.4458 51.8851 57.8878C51.6057 57.3297 51.4421 56.7209 51.4041 56.0979C51.3662 55.475 51.4548 54.8508 51.6645 54.263C51.8742 53.6752 52.2007 53.1359 52.6243 52.6776C53.0479 52.2192 53.5599 51.8514 54.1295 51.5962L54.3823 51.4606ZM60.8203 41.8529C62.0469 41.8529 63.2233 41.3656 64.0907 40.4983C64.958 39.6309 65.4453 38.4545 65.4453 37.2279C65.4453 36.0013 64.958 34.8249 64.0907 33.9575C63.2233 33.0902 62.0469 32.6029 60.8203 32.6029C59.5937 32.6029 58.4173 33.0902 57.5499 33.9575C56.6826 34.8249 56.1953 36.0013 56.1953 37.2279C56.1953 38.4545 56.6826 39.6309 57.5499 40.4983C58.4173 41.3656 59.5937 41.8529 60.8203 41.8529Z"
        fill="#E9FAF9"
      />
    </svg>
  </div> -->
  <!-- comparison chart -->
  <section class="mb-20 max-w-5xl mx-auto px-6 mt-32">
    <div
      class="prose mt-12 !max-w-none opacity-0 translate-y-8 mx-auto"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: 0.25,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    >
      <h2
        class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                y: 0,
                ease: 'expo',
                delay: 0.2,
              })
            }
          }
        "
      >
        WHAT WILL NEPTUNE COVER ON A HOME?
      </h2>

      <p
        class="text-neutral-600 opacity-0 translate-y-8 max-w-[780px]"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1.0,
                opacity: 1,
                delay: 0.25,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        A typical homeowners insurance policy does not cover damage that results
        from rising water. This is where Neptune steps in to provide
        comprehensive solutions to cover your home in the event of a flood.
      </p>

      <NeptuneVsNFIPResidential />
      <div class="text-center mt-12">
        <a
          :href="linkUrl"
          class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
          >Get Quote</a
        >
      </div>
    </div>
  </section>
  <!-- /comparison chart -->

  <section class="prose mx-auto mt-40 mb-16 px-6">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-0 leading-tight opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      THE NEPTUNE ADVANTAGE
    </h2>
    <h3
      class="uppercase font-extrabold text-neutral-600 mb-4 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Optional coverages suited just for you
    </h3>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Neptune Residential Flood Insurance provides coverage for items not
      covered in the National Flood Insurance Program, such as the contents of
      your basement, structures not attached to your home such as a garage, pool
      house, or shed, grills and pools, and greater amounts for your home and
      contents.
    </p>
  </section>
  <section class="grid md:grid-cols-2 gap-4 max-w-5xl mx-auto px-4">
    <AdvantageCard
      class="opacity-0 translate-y-8"
      v-for="(card, i) in cards"
      :data="card"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              delay: i * 0.08,
              y: 0,
              ease: 'expo',
            })
          }
        }
      "
    />
  </section>

  <section
    class="flex items-center justify-center mt-24 bg-[#D5EAE8] py-24 px-4"
  >
    <div class="max-w-5xl mx-auto w-full">
      <iframe
        src="https://www.youtube.com/embed/KRibwaO3Br0?si=oUsD0NVwPf1X2EeG"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        class="w-full aspect-video rounded-xl"
      ></iframe>
    </div>
  </section>

  <Testimonials
    :data="{
      ...data,
      default_get_quote_link: {
        url: `${data.baseUrl}/consumer-app?product-type=residential`,
      },
    }"
    :baseUrl="data.baseUrl"
  />

  <section class="prose mx-auto my-24 px-6">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold mb-4 leading-tight opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      LOW RISK DOES NOT MEAN<br />ZERO RISK.
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Many homeowners believe they only need flood insurance if their mortgage
      company or bank requires it, which is mostly when the home is located in
      areas categorized as “Special Flood Hazard” (SFHAs). The fact is, you
      don’t need to live on the coast to be at risk, and over 25% of flood
      claims historically have been filed outside of these SFHAs. Wherever it
      rains, it can flood, regardless of your proximity to a body of water.
    </p>
  </section>

  <section class="mx-auto max-w-5xl py-20 px-6 relative">
    <h2
      class="text-[32px] font-wide font-extrabold tracking-[2px] leading-snug mb-5 opacity-0 translate-y-8 uppercase"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1,
              opacity: 1,
              y: 0,
              delay: 0.2,
              ease: 'expo',
            })
          }
        }
      "
    >
      {{ data?.residential_faqs?.headline || 'Frequently asked questions' }}
    </h2>
    <FAQs
      :data="{
        faqs:
          data?.buying_flood_insurance_faqs?.questions_and_answers ||
          residentialFaqs,
      }"
    />
  </section>

  <CallToAction :baseUrl="data.baseUrl" />
</template>
<script setup>
import { ArrowRightIcon, StarIcon } from '@heroicons/vue/20/solid'
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'
import AdvantageCard from '@/components/AdvantageCard.vue'
import Testimonials from '@/components/Testimonials.vue'
import FAQs from '@/components/FAQs.vue'
import NeptuneVsNFIPResidential from '@/components/NeptuneVsNFIPResidential.vue'
import QuoteForm from '@/components/QuoteForm.vue'
import { residentialFaqs } from '@/data/faqs.js'

import { reactive, ref } from 'vue'
import { useUTMLink } from '@/composables/useUTMLink'
const { data } = defineProps(['data'])
const { linkUrl } = useUTMLink(
  `${data.baseUrl}/consumer-app?product-type=residential`,
)

const benefits = [
  'Medical insurance',
  '401(k) Match',
  'Dental Insurance',
  'Unlimited PTO',
  'Vision Insurance',
  'Training & Continuing Professional Education',
  'Life Insurance',
  'Paid Community Volunteering Opportunities',
  'Short-Term Disability',
  'Long-Term Disability',
]

const cards = [
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.2 137.6" class="h-8 text-brand-blue" fill="currentColor">
  <g id="Group_7" data-name="Group 7" transform="translate(-2251.4 -1328.2)">
    <g id="Group_6" data-name="Group 6" transform="translate(2203 1277)">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="66.8" height="133.6" transform="translate(86.6 53.2)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      <g id="Group_1" data-name="Group 1">
        <rect id="Rectangle_2" data-name="Rectangle 2" width="11.1" height="13.9" transform="translate(101.9 72.7)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_3" data-name="Rectangle 3" width="11.1" height="13.9" transform="translate(127 72.7)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_4" data-name="Rectangle 4" width="11.1" height="13.9" transform="translate(101.9 128.4)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_5" data-name="Rectangle 5" width="11.1" height="13.9" transform="translate(127 128.4)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_6" data-name="Rectangle 6" width="11.1" height="13.9" transform="translate(101.9 100.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_7" data-name="Rectangle 7" width="11.1" height="13.9" transform="translate(127 100.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      </g>
      <rect id="Rectangle_8" data-name="Rectangle 8" width="22.3" height="22.3" transform="translate(108.9 164.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      <rect id="Rectangle_9" data-name="Rectangle 9" width="33.4" height="0.1" transform="translate(103.3 164.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      <g id="Group_3" data-name="Group 3">
        <rect id="Rectangle_10" data-name="Rectangle 10" width="36.2" height="111.3" transform="translate(153.4 75.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <g id="Group_2" data-name="Group 2">
          <rect id="Rectangle_11" data-name="Rectangle 11" width="11.1" height="13.9" transform="translate(165.9 92.2)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
          <rect id="Rectangle_12" data-name="Rectangle 12" width="11.1" height="13.9" transform="translate(165.9 147.8)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
          <rect id="Rectangle_13" data-name="Rectangle 13" width="11.1" height="13.9" transform="translate(165.9 120)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        </g>
      </g>
      <g id="Group_5" data-name="Group 5">
        <rect id="Rectangle_14" data-name="Rectangle 14" width="36.2" height="111.3" transform="translate(50.4 75.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <g id="Group_4" data-name="Group 4">
          <rect id="Rectangle_15" data-name="Rectangle 15" width="11.1" height="13.9" transform="translate(62.9 92.2)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
          <rect id="Rectangle_16" data-name="Rectangle 16" width="11.1" height="13.9" transform="translate(62.9 147.8)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
          <rect id="Rectangle_17" data-name="Rectangle 17" width="11.1" height="13.9" transform="translate(62.9 120)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`,
    headline: 'TEMPORARY LIVING EXPENSES (UP TO $20,000)',
    body: 'If you are unable to live in your building, we will reimburse 75% of covered temporary living expenses incurred, up to the selected $10,000 or $20,000 limit of coverage.',
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 text-brand-blue" viewBox="0 0 131 74.3">
  <g id="Group_14" data-name="Group 14" transform="translate(-2423.5 -1550.8)">
    <g id="Group_10" data-name="Group 10" transform="translate(2369 1468)">
      <g id="Group_9" data-name="Group 9">
        <g id="Group_8" data-name="Group 8">
          <path id="Path_2" data-name="Path 2" d="M86.1,120.1Z" fill="#eb1f80"/>
          <path id="Path_3" data-name="Path 3" d="M86.1,117.9a2.1,2.1,0,1,0,2.1,2.1,2.182,2.182,0,0,0-2.1-2.1Z" fill="currentColor"/>
        </g>
      </g>
    </g>
    <g id="Group_13" data-name="Group 13" transform="translate(2369 1468)">
      <g id="Group_12" data-name="Group 12">
        <g id="Group_11" data-name="Group 11">
          <path id="Path_4" data-name="Path 4" d="M153.9,120.1Z" fill="#eb1f80"/>
          <path id="Path_5" data-name="Path 5" d="M153.9,117.9A2.1,2.1,0,1,0,156,120a2.182,2.182,0,0,0-2.1-2.1Z" fill="currentColor"/>
        </g>
      </g>
    </g>
    <rect id="Rectangle_18" data-name="Rectangle 18" width="127" height="70.3" transform="translate(2425.5 1552.8)" fill="none" stroke="currentColor" stroke-width="4"/>
    <path id="Path_6" data-name="Path 6" d="M138.7,142.6h20.8a10.755,10.755,0,0,1,10.8-10.8V108.3a10.755,10.755,0,0,1-10.8-10.8H138.7" transform="translate(2369 1468)" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="4"/>
    <path id="Path_8" data-name="Path 8" d="M120,103.7c5.7,0,10.6,7.5,10.6,16.3s-4.8,16.3-10.6,16.3c-5.7,0-10.5-7.5-10.5-16.3s4.8-16.3,10.5-16.3m0-4c-8,0-14.5,9.1-14.5,20.3s6.5,20.3,14.5,20.3,14.6-9.1,14.6-20.3c-.1-11.2-6.6-20.3-14.6-20.3Z" transform="translate(2369 1468)" fill="currentColor"/>
    <path id="Path_9" data-name="Path 9" d="M101.7,97.4H80.5a10.755,10.755,0,0,1-10.8,10.8v23.5a10.755,10.755,0,0,1,10.8,10.8h21.2" transform="translate(2369 1468)" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="4"/>
  </g>
</svg>
`,
    headline: 'REPLACEMENT COST ON CONTENTS',
    body: 'Contents are paid out as actual cash value (ACV). If you would like the claim settled for the replacement cost of the items at the time of the loss without depreciation, add this extra protection.',
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 text-brand-blue" viewBox="0 0 165.6 161.375">
  <g id="Group_17" data-name="Group 17" transform="translate(-2492.2 -1744.925)">
    <g id="Group_16" data-name="Group 16" transform="translate(2455 1706)">
      <g id="Group_15" data-name="Group 15">
        <path id="Path_11" data-name="Path 11" d="M182,99.6,161.7,80.1V49.8H142.1V61.2L121.7,41.6a2.478,2.478,0,0,0-3.4,0L58,99.6a2.417,2.417,0,0,0,0,3.5l3.9,3.9a2.478,2.478,0,0,0,3.4.1l53-48.4a2.354,2.354,0,0,1,3.3,0l53,48.4a2.384,2.384,0,0,0,3.4-.1l3.9-3.9A2.338,2.338,0,0,0,182,99.6Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <path id="Path_12" data-name="Path 12" d="M121.7,58.7a2.354,2.354,0,0,0-3.3,0L70.9,102v51.8H169V102Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_19" data-name="Rectangle 19" width="26.1" height="37.9" transform="translate(96.5 105.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <path id="Path_13" data-name="Path 13" d="M135.7,105.5v18.3h15.7V105.5Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
        <rect id="Rectangle_20" data-name="Rectangle 20" width="41.8" height="10.5" transform="translate(88.6 143.4)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      </g>
      <rect id="Rectangle_21" data-name="Rectangle 21" width="98.1" height="44.4" transform="translate(70.9 153.9)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
      <path id="Path_14" data-name="Path 14" d="M169.1,165.1a1.032,1.032,0,0,1-1.1,1.1H154.2v9.9a1,1,0,0,1-1,1H140.6V187a1,1,0,0,1-1,1H127v10.9a1.234,1.234,0,0,1-.4.8,1.421,1.421,0,0,1-.6.2.367.367,0,0,1-.3-.1l-.7-2.5V187a1,1,0,0,1,1-1h12.6v-9.9a1,1,0,0,1,1-1h12.6v-9.9a.908.908,0,0,1,.3-.7,1.08,1.08,0,0,1,.7-.3h13.6Z" fill="currentColor"/>
      <line id="Line_1" data-name="Line 1" x2="161.6" transform="translate(39.2 153.9)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
    </g>
  </g>
</svg>
`,
    headline: 'BASEMENT CONTENTS (UP TO $10,000)',
    body: 'Expands the list of covered basement contents not included in a standard policy.',
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 text-brand-blue" viewBox="0 0 194.177 129.8">
  <g id="Group_20" data-name="Group 20" transform="translate(-2319.6 -2158.1)">
    <g id="Group_18" data-name="Group 18" transform="translate(2300 2103)">
      <path id="Path_16" data-name="Path 16" d="M97.7,124.4v40.4H69.8V124.4H97.7m9-9H60.8v58.4h45.9V115.4Z" fill="none"/>
      <path id="Path_17" data-name="Path 17" d="M128.3,124.4v19.5H111.6V124.4h16.7m9-9H102.6v37.5h34.7V115.4Z" fill="none"/>
      <path id="Path_18" data-name="Path 18" d="M106,164.8v11.1H61.5V164.8H106m9-9H52.5v29.1H115V155.8Z" fill="none"/>
    </g>
    <path id="Path_19" data-name="Path 19" d="M154.8,176.4h57.1V119.3l-28.5-17.4-28.6,17.4Z" transform="translate(2300 2103)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
    <path id="Path_20" data-name="Path 20" d="M90.8,63.6a2.342,2.342,0,0,1,1.7.7l20.4,19.6V72.4h19.6v30.3l20.3,19.5a2.417,2.417,0,0,1,0,3.5l-3.9,3.9a2.342,2.342,0,0,1-1.7.7,2.715,2.715,0,0,1-1.7-.6l-53-48.4a2.708,2.708,0,0,0-3.4,0l-53,48.4a2.716,2.716,0,0,1-1.7.6,2.193,2.193,0,0,1-1.7-.7l-3.9-3.9a2.416,2.416,0,0,1,0-3.5l60.3-58a2.715,2.715,0,0,1,1.7-.6m0-8.5a11.372,11.372,0,0,0-7.6,3l-60.3,58a10.985,10.985,0,0,0-.1,15.6l3.9,3.9a10.739,10.739,0,0,0,7.7,3.2,10.963,10.963,0,0,0,7.4-2.9l49-44.7,49,44.7a11.069,11.069,0,0,0,7.4,2.9,10.573,10.573,0,0,0,7.7-3.2l3.9-3.9a10.986,10.986,0,0,0-.1-15.6L141,99.1V64H104.6l-6.1-5.8a11.432,11.432,0,0,0-7.7-3.1Z" transform="translate(2300 2103)" fill="none"/>
    <path id="Path_21" data-name="Path 21" d="M90.8,80.7a2.715,2.715,0,0,1,1.7.6l47.4,43.3v51.8H41.7V124.6L89.1,81.3a2.716,2.716,0,0,1,1.7-.6m0-8.5a10.963,10.963,0,0,0-7.4,2.9L36,118.4l-2.8,2.5v63.9H148.1V120.9l-2.8-2.5L98.2,75.1a10.781,10.781,0,0,0-7.4-2.9Z" transform="translate(2300 2103)" fill="none"/>
    <rect id="Rectangle_22" data-name="Rectangle 22" width="37.1" height="37.9" transform="translate(2465.7 2241.2)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
    <g id="Group_19" data-name="Group 19" transform="translate(2300 2103)">
      <path id="Path_22" data-name="Path 22" d="M152.8,122.2l-20.3-19.5V72.4H112.9V83.8L92.5,64.2a2.478,2.478,0,0,0-3.4,0l-60.3,58a2.417,2.417,0,0,0,0,3.5l3.9,3.9a2.478,2.478,0,0,0,3.4.1l53-48.4a2.354,2.354,0,0,1,3.3,0l53,48.4a2.384,2.384,0,0,0,3.4-.1l3.9-3.9A2.338,2.338,0,0,0,152.8,122.2Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
      <path id="Path_23" data-name="Path 23" d="M92.5,81.3a2.354,2.354,0,0,0-3.3,0L41.8,124.6v51.8h98.1V124.6Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
      <rect id="Rectangle_23" data-name="Rectangle 23" width="26.1" height="37.9" transform="translate(67.3 128.1)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
      <path id="Path_24" data-name="Path 24" d="M106.5,128.1v18.3h15.7V128.1Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
      <rect id="Rectangle_24" data-name="Rectangle 24" width="41.8" height="10.5" transform="translate(59.4 166)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="3.755"/>
    </g>
  </g>
</svg>
`,
    headline: 'UNATTACHED STRUCTURES (UP TO $100,000)',
    body: 'Neptune will pay up to the limit purchased for all unattached structures combined, excluding dwelling structures. This limit is in addition to Coverage A.',
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 text-brand-blue" viewBox="0 0 134.702 100">
  <g id="NewGroupe16_1_" transform="translate(-52.6 -70)">
    <g id="NewGroup1-8">
      <g id="Group_21" data-name="Group 21">
        <path id="Path_26" data-name="Path 26" d="M179.5,70H60.5a7.853,7.853,0,0,0-7.9,7.9v84.2a7.853,7.853,0,0,0,7.9,7.9h47.6a2.6,2.6,0,0,0,0-5.2H60.5a2.582,2.582,0,0,1-2.6-2.6V77.9a2.582,2.582,0,0,1,2.6-2.6H179.4a2.582,2.582,0,0,1,2.6,2.6v84.2a2.582,2.582,0,0,1-2.6,2.6H131.8a2.6,2.6,0,1,0,0,5.2h47.6a7.853,7.853,0,0,0,7.9-7.9V77.9a7.769,7.769,0,0,0-7.8-7.9Z" fill="currentColor"/>
        <path id="Path_27" data-name="Path 27" d="M117.4,120.1a5.5,5.5,0,1,1-5.5,5.5,5.362,5.362,0,0,1,5.5-5.5Zm0,16.3a10.8,10.8,0,1,0-10.8-10.8,10.819,10.819,0,0,0,10.8,10.8Z" fill="currentColor"/>
        <path id="Path_28" data-name="Path 28" d="M130.2,100a22.986,22.986,0,0,0,18.5,9.6h.7a22.349,22.349,0,0,1,22.2,21.9A22.195,22.195,0,0,1,149.4,154H102.6a34.143,34.143,0,0,1-34.1-34.4A34.265,34.265,0,0,1,94.4,86.8v5.5a29.113,29.113,0,0,0-20.6,27.4,28.322,28.322,0,0,0,8.4,20.5,28.656,28.656,0,0,0,20.5,8.5h46.8a16.7,16.7,0,0,0,12.1-5.1,16.91,16.91,0,0,0,4.9-12.2,17.121,17.121,0,0,0-16.9-16.8h-.7a28.387,28.387,0,0,1-22.8-11.8,28.685,28.685,0,0,0-15.8-10.9V86.5A35.968,35.968,0,0,1,130.2,100Zm-22.7.5a2.582,2.582,0,0,0,2.6-2.6v-.2a23.993,23.993,0,0,1,11.6,8.5,33.5,33.5,0,0,0,27,13.9h.7a11.759,11.759,0,0,1,11.7,11.6,11.962,11.962,0,0,1-3.4,8.4,11.417,11.417,0,0,1-8.3,3.5H102.6a23.559,23.559,0,0,1-23.5-23.8A23.687,23.687,0,0,1,94.5,97.9a2.647,2.647,0,0,0,2.6,2.6,2.582,2.582,0,0,0,2.6-2.6V96.6a24.519,24.519,0,0,1,2.7-.2h.3a17.7,17.7,0,0,1,2.3.1v1.4a2.385,2.385,0,0,0,2.5,2.6Zm-5.4-14.6h.4a17.7,17.7,0,0,1,2.3.1v5.3a17.7,17.7,0,0,0-2.3-.1h-.4a22.753,22.753,0,0,0-2.6.2V86a23.484,23.484,0,0,1,2.6-.1Zm5.4-7.5a2.678,2.678,0,0,0-2.6,2.3,26.2,26.2,0,0,0-2.8-.1,20.9,20.9,0,0,0-2.5.1A2.678,2.678,0,0,0,97,78.4,2.582,2.582,0,0,0,94.4,81v.4a39.614,39.614,0,0,0-31.2,38.3,39.265,39.265,0,0,0,39.3,39.6h46.8a27.488,27.488,0,0,0,27.5-27.9,27.6,27.6,0,0,0-27.5-27.1h-.4a17.732,17.732,0,0,1-14.5-7.4A39.39,39.39,0,0,0,110,81.3V81a2.5,2.5,0,0,0-2.5-2.6Z" fill="currentColor"/>
      </g>
    </g>
  </g>
</svg>
`,
    headline: 'POOL REPAIR AND REFILL (UP TO $20,000)',
    body: 'Cover in-ground swimming pool repair and refill costs.',
  },
  {
    icon: '',
    headline: 'LOSS OF RENTS (UP TO $20,000)',
    body: 'If you own a rental property, protect your investment with up to $20,000 in rental loss if the property becomes uninhabitable due to flooding.',
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-8 text-brand-blue">
  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clip-rule="evenodd" />
</svg>`,
    headline: 'Building Betterments & Improvements',
    body: 'For tenant contents-only policies, receive coverage up to the policy limit for improvements you made to the building.',
  },
]
</script>

<style>
.swiper-wrapper {
  align-items: stretch !important;
}
</style>
