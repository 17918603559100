<template>
  <Teleport to="body">
    <TransitionModal>
      <TeamModal
        v-if="teamModal.open"
        :data="selectedTeamMember"
        @close="teamModal.open = false"
      />
    </TransitionModal>
  </Teleport>
  <img
    src="@/assets/face-blue.svg"
    alt=""
    class="absolute -left-[100px] w-[970px] opacity-[0.10] z-10 mix-blend-difference pointer-events-none"
  />

  <section class="pt-32 pb-40 px-6 relative rounded-b-[50px]">
    <div class="absolute inset-0 bg-brand-blue rounded-b-[50px]"></div>
    <div class="sm:text-center">
      <h1
        class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] mx-auto leading-snug mb-5 opacity-0 translate-y-8 text-white"
        data-test="team.aboutTitle"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        ABOUT NEPTUNE
      </h1>
      <p
        class="max-w-[738px] mx-auto text-neutral-600 opacity-0 translate-y-8 text-white"
        data-test="team.aboutText"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        Founded in 2018, Neptune Flood is the largest private flood insurance
        provider in the US, offering primary and excess residential, commercial,
        and condominium insurance throughout the United States. Our effortless,
        intuitive, and on-demand platform allows agents and customers to quote
        and bind a policy in under two minutes, all built by our stellar
        in-house technology team. We partner with AM Best rated insurance
        companies to offer flood insurance in 49 states plus Washington DC. In
        October 2021, Neptune acquired Jumpstart, a parametric insurance
        company, adding parametric earthquake to our portfolio. In May of 2024,
        Neptune acquired Charles River Data, enhancing our in-house data
        science, artificial intelligence, and machine learning capabilities. We
        are headquartered in downtown St. Petersburg, FL.
      </p>
    </div>
  </section>

  <section class="mx-auto max-w-7xl py-20 px-6 relative z-10">
    <div class="sm:text-center">
      <h2
        class="text-[32px] font-wide font-extrabold tracking-[2px] max-w-[589px] mx-auto leading-snug mb-5 opacity-0 translate-y-8"
        data-test="team.teamTitle"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                ease: 'expo',
              })
            }
          }
        "
      >
        TEAM NEPTUNE: LEADERSHIP FOR THE 21ST CENTURY. AND BEYOND.
      </h2>
      <p
        class="max-w-[718px] mx-auto text-neutral-600 opacity-0 translate-y-8"
        data-test="team.teamText"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1,
                ease: 'expo',
              })
            }
          }
        "
      >
        The leadership team at Neptune Flood Insurance has deep backgrounds and
        experience in technology, risk management, predictive analytics and
        private flood insurance
      </p>
    </div>
    <div
      class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-10 mt-16"
    >
      <div
        v-for="(member, i) in data.members"
        v-intersection-observer="
          ([{ isIntersecting, target }]) => {
            if (isIntersecting) {
              gsap.to(target, {
                duration: 1,
                opacity: 1,
                y: 0,
                delay: 0.1 + 0.03 * i,
                ease: 'expo',
              })
            }
          }
        "
        class="opacity-0 translate-y-8"
      >
        <div
          class="relative group"
          :data-test="`team.picture-${member.name}`"
          @click="
            () => {
              selectedTeamMember = member
              teamModal.open = true
            }
          "
          @mouseenter="
            () => {
              hoveredMember = member
            }
          "
          @mouseleave="
            () => {
              hoveredMember = null
            }
          "
        >
          <img
            :src="member.image.url"
            :alt="member.image.alt"
            class="aspect-[250/307] w-full bg-gray-200 rounded-2xl object-cover"
          />
          <div
            class="absolute inset-0 z-10 transition flex items-center justify-center cursor-pointer"
          >
            <div
              class="absolute inset-0 rounded-2xl bg-brand-blue opacity-0 group-hover:opacity-75 transition"
            ></div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 translate-y-4"
              enter-to-class="transform opacity-100 translate-y-0"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 translate-y-0"
              leave-to-class="transform opacity-0 -translate-y-4"
            >
              <h2
                v-if="hoveredMember?.name === member.name"
                :key="hoveredMember?.name"
                class="font-extrabold uppercase tracking-[1px] uppercase font-wide text-white relative z-10"
              >
                Learn More
              </h2>
            </transition>
          </div>
        </div>

        <div class="text-center mt-2">
          <h2
            class="font-extrabold font-wide tracking-[1px] uppercase"
            :data-test="`team.member-${member.name}`"
          >
            {{ member.name }}
          </h2>
          <p
            class="text-sm text-gray-600"
            :data-test="`team.title-${member.name}`"
          >
            {{ member.title }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <CallToAction :baseUrl="data.baseUrl" />
</template>
<script setup>
import gsap from 'gsap'
import { vIntersectionObserver } from '@vueuse/components'
import CallToAction from '@/components/CallToAction.vue'
import TransitionModal from '@/components/TransitionModal.vue'
import TeamModal from '@/components/TeamModal.vue'

import { reactive, ref } from 'vue'
const { data } = defineProps(['data'])

const teamModal = reactive({
  open: false,
})

const selectedTeamMember = ref(data.members[0])
const hoveredMember = ref(null)
</script>
